import React from 'react';
import { forwardRef } from "react"
import ReactDOM from 'react-dom';
import DatePicker from "react-datepicker";
import parseISO from "date-fns/parseISO";
import getWeek from "date-fns/getWeek";
import getDate from "date-fns/getDate";
import getISOWeek from "date-fns/getISOWeek";
import moment from 'moment';

function formatWeekNumber(date, weekStartsOn){
    return getWeek(date, {weekStartsOn});
} 
class Datepicker extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            expanded: false
        }
        this.setExpanded = this.setExpanded.bind(this);
        this.getHeight = this.getHeight.bind(this);
    }
    componentDidMount() {
    }

    componentWillUnmount(){
    }
    
    componentDidUpdate(prevProps) {
    }
    
    getHeight() {
      const el = this.props.dateref && document.getElementsByClassName(this.props.dateref.current.props.popperClassName)[0]
      const elHeight = el && el.offsetHeight;
 
      return this.state.expanded ? elHeight : 0;
    }

    setExpanded(expanded) {
        this.setState({expanded}, ()=>{
          const { onExpand } = this.props;
          if (onExpand !== undefined) {
            onExpand({expanded, height: this.getHeight()})
          }
        });
    }
    
    filterDays(date, disabledDays = '') {
        if(!disabledDays)
            return true;        
        if(typeof disabledDays == 'string')
            disabledDays = disabledDays.split('');
        disabledDays = disabledDays.map(day=>Number(day));
        return !(disabledDays.includes(Number(date.getDay())));
    }

    handleDateChangeRaw = (e) => {
        e.preventDefault();
    }

    render() {
        let {date, dateChange, name, className="form-control", currentStartDate = null, endDate = null, startDate = null, currentEndDate = null, placeholder = '', daysOfWeekDisabled='', monthsShown, showYearDropdown = false, selectsStart, selectsEnd, dateref, calendarStartDay = 0, showWeekNumbers = false, readonly = false, reportDates, datepickerPosition = 'bottom-start', showMonthYearPicker = false, dateFormat = 'yyyy-MM-dd', includeDates, filterDate} = this.props;

        if(date)
            date = parseISO(date);
        if(currentStartDate)
            currentStartDate = parseISO(currentStartDate);
        if(currentEndDate)
            currentEndDate = parseISO(currentEndDate);        
        if(endDate)
            endDate = parseISO(endDate);
        
        if(!filterDate)
          filterDate= ()=>{return true}

        if(startDate)
            startDate = parseISO(startDate);

        const ExampleCustomInput = forwardRef(({ value, placeholder, onClick }, ref) => (
            <button type="button" className="datepicker-custom-input" onClick={onClick} ref={ref}>
                {!value ? placeholder : value}
            </button>
            ));
        const renderDayContents = (day, date) => {
            if (reportDates) {
                let isEqual = reportDates.includes(moment(date).format('YYYY-MM-DD').toString())
                return <span className={`inner-day-holder ${isEqual ? "missing-date" : ""}`}>
                    <span className="inner-day-cell">{getDate(date)}</span>
                    <span className="after"></span></span>;
            } else {
                return <span className="inner-day-holder">
                <span className="inner-day-cell">{getDate(date)}</span>
                <span className="after"></span></span>;
            }
        };
        
        if(includeDates)
          includeDates = {includeDates};
        else
          includeDates = {};
        

        const popperPosition = datepickerPosition;
        return <DatePicker 
            selected={date}  
            name={name}
            dateFormat={dateFormat}
            minDate={startDate}
            maxDate={endDate}
            startDate={currentStartDate}
            endDate={currentEndDate}
            className={className}
            placeholderText={placeholder}
            filterDate={(date)=>this.filterDays(date, daysOfWeekDisabled)}
            onChange={(date) => dateChange(date)}
            monthsShown={monthsShown}
            showYearDropdown={showYearDropdown}
            yearDropdownItemNumber={100}
            dateFormatCalendar='MMMM'
            ref={dateref}  
            formatWeekNumber={(date)=>formatWeekNumber(date, calendarStartDay)}
            popperPlacement={popperPosition}
            onCalendarOpen={()=>this.setExpanded(true)}
            onCalendarClose={()=>this.setExpanded(false)}
            popperClassName="picker-popper"
            selectsStart={selectsStart}
            selectsEnd={selectsEnd}
            calendarStartDay={calendarStartDay}
            showWeekNumbers={showWeekNumbers}
            readOnly={readonly}
            customInput={<ExampleCustomInput readonly={readonly} />}
            renderDayContents={renderDayContents}
            showMonthYearPicker={showMonthYearPicker}
            /* remove if not needed */
            fixedHeight={true}
            filterDate = {filterDate}
            showMonthYearPicker={showMonthYearPicker}
            {...includeDates}
            popperModifiers={[
                {
                  name: "flip",
                  enabled: false,
                },
              ]}
        />;
    }
  }
  export default Datepicker;
