//import $ from 'jquery';
import Param from '../../helpers/Param';
import AuthHeader from '../../helpers/AuthHeader';
import BuildQS from '../../helpers/BuildQS';
import config from '../../config/config';

import audiencePlaylist from './stubs/audience_playlist';

export const audienceService = {
    getStreamsStats,
    getDemographicsStats,
    getTerritoryStats,
    getDemographicsTerritoryStats,
    getSourceStats,
    getSourceTimeseries,
    getSourcePlaylists,    
    getDeviceStats,
    getPlaylistStats,
    getTopPlaylistStats,
    getRepeatListeners,  
    getRepeatListenersTimeseries,
    getRepeatListenersRatio  
};

function getStreamsStats(params, globalFilter){
    return config.live ? getStreamsStatsLive(params, globalFilter) : getStreamsStatsStatic();
}

function getStreamsStatsLive(params, globalFilter) {
    
    let baseUrl = config.apis.v3_data+'/simple',
    qs = BuildQS(globalFilter),
        requestOptions = {
        method: 'POST',
        headers: AuthHeader({'Content-Type': 'application/json'})
    };

    qs.query_name = 'audience';
    qs.mode = 'timeseries';
    // qs.offset = 0;
    
    let {sorted, filtered} = params;
    if(sorted !== undefined){
        qs.order_by = sorted.field;
        qs.order_dir = sorted.dir;
    }
    
    if(filtered !== undefined && filtered.length){
        for(let filter of filtered) {
            let value = filter.value;
            if(!value)
                continue;
            if(value instanceof Array) {
                value = value.join(',');
                if(!value)
                    continue;
                qs[filter.id] = value;
            }
            else {
                qs[filter.id] = value;    
            }
        }            
    }    
    
    
    //baseUrl += '?' + Param(qs);
    requestOptions.body = JSON.stringify(qs);
    return fetch(baseUrl, requestOptions)
        .then(res=>res.json());
    }

function getStreamsStatsStatic(params) {
    let baseUrl = `${config.apis.sales}/api/static/audience.json`;
    return fetch(baseUrl, {
        method: 'GET'
    })
    .then(res=>{   
        return res.json()
    })
}

function getDemographicsStats(params, globalFilter, fields){
    return config.live ? getDemographicsStatsLive(params, globalFilter, fields) : getDemographicsStatsStatic();
}

function getDemographicsStatsLive(params, globalFilter, fields) {
    
    let baseUrl = config.apis.v3_data+'/simple',
    qs = BuildQS(globalFilter),
    requestOptions = {
        method: 'POST',
        headers: AuthHeader({'Content-Type': 'application/json'})
    };

    qs.query_name = 'detail_demographics';
    qs.mode = 'main';
    qs.row_limit = 50;
    // qs.offset = 0;
    
    let {sorted, filtered} = params;
    if(sorted !== undefined){
        qs.order_by = sorted.field;
        qs.order_dir = sorted.dir;
    }
    
    if(filtered !== undefined && filtered.length){
        for(let filter of filtered) {
            let value = filter.value;
            if(!value)
                continue;
            if(value instanceof Array) {
                value = value.join(',');
                if(!value)
                    continue;
                qs[filter.id] = value;
            }
            else {
                qs[filter.id] = value;    
            }
        }            
    }    
    if(fields) {
        if(Array.isArray(fields))
            fields = fields.join(',');
        qs.fields = fields;
    }

    
    //baseUrl += '?' + Param(qs);
    requestOptions.body = JSON.stringify(qs);
    
    return fetch(baseUrl, requestOptions)
        .then(res=>res.json());
    }

function getDemographicsStatsStatic(params) {
    let baseUrl = `${config.apis.sales}/api/static/audience_demographics.json`;
    return fetch(baseUrl, {
        method: 'GET'
    })
    .then(res=>{   
        return res.json()
    })
}

function getTerritoryStats(params, globalFilter, fields){
    return config.live ? getTerritoryStatsLive(params, globalFilter, fields) : getTerritoryStatsStatic();
}

function getTerritoryStatsLive(params, globalFilter, fields) {
    
    let baseUrl = config.apis.v3_data+'/simple',
    qs = BuildQS(globalFilter),
    requestOptions = {
        method: 'POST',
        headers: AuthHeader({'Content-Type': 'application/json'})
    };

    qs.query_name = 'audience_territory';
    qs.mode = 'main';
    // qs.offset = 0;
    
    let {sorted, filtered, limit} = params;
    if(sorted !== undefined){
        qs.order_by = sorted.field;
        qs.order_dir = sorted.dir;
    }
    
    if(filtered !== undefined && filtered.length){
        for(let filter of filtered) {
            let value = filter.value;
            if(!value)
                continue;
            if(value instanceof Array) {
                value = value.join(',');
                if(!value)
                    continue;
                qs[filter.id] = value;
            }
            else {
                qs[filter.id] = value;    
            }
        }            
    }    
    if(limit !== undefined){
        qs.row_limit = limit;
    }
    if(fields) {
        if(Array.isArray(fields))
            fields = fields.join(',');
        qs.fields = fields;
    }
    //baseUrl += '?' + Param(qs);
    requestOptions.body = JSON.stringify(qs);
    return fetch(baseUrl, requestOptions)
        .then(res=>res.json());
    }

function getTerritoryStatsStatic(params) {
    let baseUrl = `${config.apis.sales}/api/static/audience_territory.json`;
    return fetch(baseUrl, {
        method: 'GET'
    })
    .then(res=>{   
        return res.json()
    })
}

function getDemographicsTerritoryStats(params, globalFilter, fields){
    return config.live ? getDemographicsTerritoryStatsLive(params, globalFilter, fields) : getDemographicsTerritoryStatsStatic();
}

function getDemographicsTerritoryStatsLive(params, globalFilter, fields) {
    
    let baseUrl = config.apis.v3_data+'/simple',
    qs = BuildQS(globalFilter),
    requestOptions = {
        method: 'POST',
        headers: AuthHeader({'Content-Type': 'application/json'})
    };

    qs.query_name = 'audience_demographics';
    qs.mode = 'main';
    // qs.offset = 0;
    
    let {sorted, filtered} = params;
    if(sorted !== undefined){
        qs.order_by = sorted.field;
        qs.order_dir = sorted.dir;
    }
    
    if(filtered !== undefined && filtered.length){
        for(let filter of filtered) {
            let value = filter.value;
            if(!value)
                continue;
            if(value instanceof Array) {
                value = value.join(',');
                if(!value)
                    continue;
                qs[filter.id] = value;
            }
            else {
                qs[filter.id] = value;    
            }
        }            
    }    
    
    if(fields) {
        if(Array.isArray(fields))
            fields = fields.join(',');
        qs.fields = fields;
    }

    //baseUrl += '?' + Param(qs);
    requestOptions.body = JSON.stringify(qs);
    return fetch(baseUrl, requestOptions)
        .then(res=>res.json());
    }

function getDemographicsTerritoryStatsStatic(params) {
    let baseUrl = `${config.apis.sales}/api/static/audience_demographics_territory.json`;
    return fetch(baseUrl, {
        method: 'GET'
    })
    .then(res=>{   
        return res.json()
    })
}


function getSourceStats(params, globalFilter, discovery = false, fields){
    return config.live ? getSourceStatsLive(params, globalFilter, discovery, fields) : getSourceStatsStatic(discovery);
}

function getSourceStatsLive(params, globalFilter, discovery, fields) {
    
    let baseUrl = config.apis.v3_data+'/simple',
    qs = BuildQS(globalFilter),
    requestOptions = {
        method: 'POST',
        headers: AuthHeader({'Content-Type': 'application/json'})
    };

    qs.query_name = discovery?'audience_source_discovery':'audience_source';
    qs.mode = 'main';
    // qs.offset = 0;
    
    let {sorted, filtered} = params;
    if(sorted !== undefined){
        qs.order_by = sorted.field;
        qs.order_dir = sorted.dir;
    }
    
    if(filtered !== undefined && filtered.length){
        for(let filter of filtered) {
            let value = filter.value;
            if(!value)
                continue;
            if(value instanceof Array) {
                value = value.join(',');
                if(!value)
                    continue;
                qs[filter.id] = value;
            }
            else {
                qs[filter.id] = value;    
            }
        }            
    }    
    
    if(fields) {
        if(Array.isArray(fields))
            fields = fields.join(',');
        qs.fields = fields;
    }

    //baseUrl += '?' + Param(qs);
    requestOptions.body = JSON.stringify(qs);
    return fetch(baseUrl, requestOptions)
        .then(res=>res.json());
    }

function getSourceStatsStatic(discovery) {
    let baseUrl = `${config.apis.sales}/api/static/audience_source${discovery?'_discovery':''}.json`;
    return fetch(baseUrl, {
        method: 'GET'
    })
    .then(res=>{   
        return res.json()
    })
}

function getSourceTimeseries(params, globalFilter, discovery = false){
    return config.live ? getSourceTimeseriesLive(params, globalFilter, discovery) : getSourceTimeseriesStatic(discovery);
}

function getSourceTimeseriesLive(params, globalFilter, discovery) {
    
    let baseUrl = config.apis.v3_data+'/simple',
    qs = BuildQS(globalFilter),
    requestOptions = {
        method: 'POST',
        headers: AuthHeader({'Content-Type': 'application/json'})
    };

    qs.query_name = discovery?'audience_source_discovery':'audience_source';
    qs.mode = 'timeseries';
    // qs.offset = 0;
    
    let {sorted, filtered} = params;
    if(sorted !== undefined){
        qs.order_by = sorted.field;
        qs.order_dir = sorted.dir;
    }
    
    if(filtered !== undefined && filtered.length){
        for(let filter of filtered) {
            let value = filter.value;
            if(!value)
                continue;
            if(value instanceof Array) {
                value = value.join(',');
                if(!value)
                    continue;
                qs[filter.id] = value;
            }
            else {
                qs[filter.id] = value;    
            }
        }            
    }    
    
    
    //baseUrl += '?' + Param(qs);
    requestOptions.body = JSON.stringify(qs);
    
    return fetch(baseUrl, requestOptions)
        .then(res=>res.json());
    }

function getSourceTimeseriesStatic(discovery) {
    let baseUrl = `${config.apis.sales}/api/static/audience_source_timeseries${discovery?'_discovery':''}.json`;
    return fetch(baseUrl, {
        method: 'GET'
    })
    .then(res=>{   
        return res.json()
    })
}

function getSourcePlaylists(params, globalFilter, discovery = false){
    return config.live ? getSourcePlaylistsLive(params, globalFilter, discovery) : getSourcePlaylistsStatic(discovery);
}

function getSourcePlaylistsLive(params, globalFilter, discovery) {
    
    let baseUrl = config.apis.v3_data+'/simple',
    qs = BuildQS(globalFilter),
    requestOptions = {
        method: 'POST',
        headers: AuthHeader({'Content-Type': 'application/json'})
    };

    qs.query_name = discovery?'audience_playlist_source_discovery':'audience_playlist_source';
    qs.mode = 'main';
    // qs.offset = 0;
    
    let {sorted, filtered} = params;
    if(sorted !== undefined){
        qs.order_by = sorted.field;
        qs.order_dir = sorted.dir;
    }
    
    if(filtered !== undefined && filtered.length){
        for(let filter of filtered) {
            let value = filter.value;
            if(!value)
                continue;
            if(value instanceof Array) {
                value = value.join(',');
                if(!value)
                    continue;
                qs[filter.id] = value;
            }
            else {
                qs[filter.id] = value;    
            }
        }            
    }    
    
    
    //baseUrl += '?' + Param(qs);
    requestOptions.body = JSON.stringify(qs);
    return fetch(baseUrl, requestOptions)
        .then(res=>res.json());
    }

function getSourcePlaylistsStatic(discovery) {
    let baseUrl = `${config.apis.sales}/api/static/audience_source_playlists${discovery?'_discovery':''}.json`;
    return fetch(baseUrl, {
        method: 'GET'
    })
    .then(res=>{   
        return res.json()
    })
}



function getDeviceStats(params, globalFilter, fields){
    return config.live ? getDeviceStatsLive(params, globalFilter, fields) : getDeviceStatsStatic();
}

function getDeviceStatsLive(params, globalFilter, fields) {
    
    let baseUrl = config.apis.v3_data+'/simple',
    qs = BuildQS(globalFilter),
    requestOptions = {
        method: 'POST',
        headers: AuthHeader({'Content-Type': 'application/json'})
    };

    qs.query_name = 'audience_device';
    qs.mode = 'main';
    // qs.offset = 0;
    
    let {sorted, filtered} = params;
    if(sorted !== undefined){
        qs.order_by = sorted.field;
        qs.order_dir = sorted.dir;
    }
    
    if(filtered !== undefined && filtered.length){
        for(let filter of filtered) {
            let value = filter.value;
            if(!value)
                continue;
            if(value instanceof Array) {
                value = value.join(',');
                if(!value)
                    continue;
                qs[filter.id] = value;
            }
            else {
                qs[filter.id] = value;    
            }
        }            
    }    
    
    if(fields) {
        if(Array.isArray(fields))
            fields = fields.join(',');
        qs.fields = fields;
    }

    //baseUrl += '?' + Param(qs);
    requestOptions.body = JSON.stringify(qs);
    return fetch(baseUrl, requestOptions)
        .then(res=>res.json());
    }

function getDeviceStatsStatic(params) {
    let baseUrl = `${config.apis.sales}/api/static/audience_device.json`;
    return fetch(baseUrl, {
        method: 'GET'
    })
    .then(res=>{   
        return res.json()
    })
}


function getPlaylistStats(params, globalFilter){
    return config.live ? getPlaylistStatsLive(params, globalFilter) : getPlaylistStatsStatic();
}

function getPlaylistStatsLive(params, globalFilter) {
    
    return Promise.resolve(audiencePlaylist);
    
    let baseUrl = config.apis.v3_data+'/simple',
    qs = BuildQS(globalFilter),
    requestOptions = {
        method: 'POST',
        headers: AuthHeader({'Content-Type': 'application/json'})
    };

    qs.query_name = 'audience_playlist';
    qs.mode = 'main';
    // qs.offset = 0;
    
    let {sorted, filtered} = params;
    if(sorted !== undefined){
        qs.order_by = sorted.field;
        qs.order_dir = sorted.dir;
    }
    
    if(filtered !== undefined && filtered.length){
        for(let filter of filtered) {
            let value = filter.value;
            if(!value)
                continue;
            if(value instanceof Array) {
                value = value.join(',');
                if(!value)
                    continue;
                qs[filter.id] = value;
            }
            else {
                qs[filter.id] = value;    
            }
        }            
    }    
    
    
    //baseUrl += '?' + Param(qs);
    requestOptions.body = JSON.stringify(qs);
    return fetch(baseUrl, requestOptions)
        .then(res=>res.json());
    }

function getPlaylistStatsStatic(params) {
    let baseUrl = `${config.apis.sales}/api/static/audience_playlist.json`;
    return fetch(baseUrl, {
        method: 'GET'
    })
    .then(res=>{   
        return res.json()
    })
}


function getRepeatListeners(params, globalFilter){
    return config.live ? getRepeatListenersLive(params, globalFilter) : getRepeatListenersStatic();
}

function getRepeatListenersLive(params, globalFilter) {
    
    let baseUrl = config.apis.v3_data+'/simple',
    qs = BuildQS(globalFilter),
    requestOptions = {
        method: 'POST',
        headers: AuthHeader({'Content-Type': 'application/json'})
    };

    qs.query_name = 'audience_repeat_listeners';
    // qs.offset = 0;
    
    let {sorted, filtered} = params;
    if(sorted !== undefined){
        qs.order_by = sorted.field;
        qs.order_dir = sorted.dir;
    }
    
    if(filtered !== undefined && filtered.length){
        for(let filter of filtered) {
            let value = filter.value;
            if(!value)
                continue;
            if(value instanceof Array) {
                value = value.join(',');
                if(!value)
                    continue;
                qs[filter.id] = value;
            }
            else {
                qs[filter.id] = value;    
            }
        }            
    }    
    
    
    //baseUrl += '?' + Param(qs);
    requestOptions.body = JSON.stringify(qs);
    return fetch(baseUrl, requestOptions)
        .then(res=>res.json());
    }

function getRepeatListenersStatic(params) {
    let baseUrl = `${config.apis.sales}/api/static/audience_repeat_listeners.json`;
    return fetch(baseUrl, {
        method: 'GET'
    })
    .then(res=>{   
        return res.json()
    })
}

function getRepeatListenersTimeseries(params, globalFilter){
    return config.live ? getRepeatListenersTimeseriesLive(params, globalFilter) : getRepeatListenersTimeseriesStatic();
}

function getRepeatListenersTimeseriesLive(params, globalFilter) {
    
    let baseUrl = config.apis.v3_data+'/simple',
    qs = BuildQS(globalFilter),
    requestOptions = {
        method: 'POST',
        headers: AuthHeader({'Content-Type': 'application/json'})
    };


    qs.query_name = 'audience_repeat_listeners';
    qs.mode = 'timeseries';
    
    let {sorted, filtered} = params;
    if(sorted !== undefined){
        qs.order_by = sorted.field;
        qs.order_dir = sorted.dir;
    }
    
    if(filtered !== undefined && filtered.length){
        for(let filter of filtered) {
            let value = filter.value;
            if(!value)
                continue;
            if(value instanceof Array) {
                value = value.join(',');
                if(!value)
                    continue;
                qs[filter.id] = value;
            }
            else {
                qs[filter.id] = value;    
            }
        }            
    }    
    
    
    //baseUrl += '?' + Param(qs);
    requestOptions.body = JSON.stringify(qs);
    return fetch(baseUrl, requestOptions)
        .then(res=>res.json());
    }

function getRepeatListenersTimeseriesStatic(params) {
    let baseUrl = `${config.apis.sales}/api/static/repeat_listeners_timeseries.json`;
    return fetch(baseUrl, {
        method: 'GET'
    })
    .then(res=>{   
        return res.json()
    })
}


function getRepeatListenersRatio(params, globalFilter){
    return true || config.live ? getRepeatListenersRatioLive(params, globalFilter) : getRepeatListenersRatioStatic();
}

function getRepeatListenersRatioLive(params, globalFilter) {
    
    let baseUrl = config.apis.v3_data+'/simple',
    qs = BuildQS(globalFilter),
    requestOptions = {
        method: 'POST',
        headers: AuthHeader({'Content-Type': 'application/json'})
    };


    qs.query_name = 'audience_repeat_listeners';
    qs.mode = 'streams_listener';
    
    let {sorted, filtered} = params;
    if(sorted !== undefined){
        qs.order_by = sorted.field;
        qs.order_dir = sorted.dir;
    }
    
    if(filtered !== undefined && filtered.length){
        for(let filter of filtered) {
            let value = filter.value;
            if(!value)
                continue;
            if(value instanceof Array) {
                value = value.join(',');
                if(!value)
                    continue;
                qs[filter.id] = value;
            }
            else {
                qs[filter.id] = value;    
            }
        }            
    }    
    
    
    //baseUrl += '?' + Param(qs);
    requestOptions.body = JSON.stringify(qs);
    return fetch(baseUrl, requestOptions)
        .then(res=>res.json());
    }

function getRepeatListenersRatioStatic(params) {
    let baseUrl = `${config.apis.sales}/api/static/repeat_listeners_ratio.json`;
    return fetch(baseUrl, {
        method: 'GET'
    })
    .then(res=>{   
        return res.json()
    })
}

function getTopPlaylistStats(params, globalFilter){
    return config.live ? getTopPlaylistStatsLive(params, globalFilter) : getTopPlaylistStatsStatic();
}

function getTopPlaylistStatsLive(params, globalFilter) {
    let baseUrl = config.apis.v3_data+'/simple',
    qs = BuildQS(globalFilter),
    requestOptions = {
        method: 'POST',
        headers: AuthHeader({'Content-Type': 'application/json'})
    };

    qs.query_name = 'audience_playlist';
    qs.mode = 'main';
    // qs.offset = 0;
    
    let {sorted, filtered} = params;
    if(sorted !== undefined){
        qs.order_by = sorted.field;
        qs.order_dir = sorted.dir;
    }
    
    if(filtered !== undefined && filtered.length){
        for(let filter of filtered) {
            let value = filter.value;
            if(!value)
                continue;
            if(value instanceof Array) {
                value = value.join(',');
                if(!value)
                    continue;
                qs[filter.id] = value;
            }
            else {
                qs[filter.id] = value;    
            }
        }            
    }    
    
    
    //baseUrl += '?' + Param(qs);
    requestOptions.body = JSON.stringify(qs);
    return fetch(baseUrl, requestOptions)
        .then(res=>res.json());
    }

function getTopPlaylistStatsStatic(params) {
    let baseUrl = `${config.apis.sales}/api/static/audience_playlist.json`;
    return fetch(baseUrl, {
        method: 'GET'
    })
    .then(res=>{   
        return res.json()
    })
}
