//import $ from 'jquery';
import Param from '../../helpers/Param';
import AuthHeader from '../../helpers/AuthHeader';
import BuildQS from '../../helpers/BuildQS';
import BuildFilterQS from '../../helpers/BuildFilterQS';
import config from '../../config/config';
import {searchService} from './search';


import topProducts from './stubs/top_products';
import productsMetadata from './stubs/products_metadata';
import topProductsTimeline from './stubs/top_products_timeline';
import audienceDatetime from './stubs/audience_datetime';


export const productsService = {
    getProducts,
    getTopProducts,
    getProductsOld,
    getProductsAutocomplete,
    getCard,
    getStreamsStats,
    getProductsByBarcode,
    getTracks,
    getMetadata,
    getMetadataByIDs,
    getTimeseriesProducts,
    compareProducts,
    compareProductDemographics,
    compareProductTerritories,
    compareProductDatetime,
    compareProductVendors,
    getOrderedTracks,
    compareProductArtist
};

function getProducts(params, globalFilter, basic){
    return config.live ? getProductsNew(params, globalFilter, basic) : getProductsStatic(basic);
}

function getProductsByBarcode(barcodes){
  const options = {
    method: 'GET',
    headers: AuthHeader()
  }
  const params = barcodes.endsWith(',') ? barcodes.replace(/\,+$/, '') : barcodes;
  const url = `${config.apis.miniCat}/v2/products/by_barcode/${params}`;
  return fetch(url, options)
  .then( response => response.json())
  .catch( error => Promise.reject(error));
}

function getProductsOld(params) {
    let { sorted, page, pageSize, filtered } = params;
    const requestOptions = {
        method: 'GET',
        headers: AuthHeader()
    };

    // TODO: API dependent, change for prod
    let baseUrl = `${config.apis.sales}/api/v1/Product`,
        qs = {};

    if(sorted !== undefined)
        qs.sort = {[sorted[0].id]: sorted[0].desc?-1:1};
    if(page !== undefined && pageSize !== undefined ) {
        qs.limit = pageSize;
        qs.skip = pageSize*params.page;
    }
    if(filtered !== undefined ) {
        let filters = [];
        for(let filter of filtered){
            let value = filter.value;
            if(!value)
                continue;
            if(value instanceof Array){
                if(value.length)
                    filters.push(`"${filter.id}": {"$in": ["${value.join('","')}"]}`);
            }
            else if(typeof value === 'string')
                filters.push(`"${filter.id}": {"$regex": "${value}", "$options": "i"}`);
            else
                filters.push(`"${filter.id}": ${value}`);

        }
        if(filters.length)
            qs.query = `{${filters.join(',')}}`;
    }
    //
    //baseUrl += '?' + Param(qs);

    let pages;
    requestOptions.body = JSON.stringify(qs);
    return fetch(baseUrl, requestOptions)
    .then(res=>{
        pages = res.headers.get('X-Total-Count');
        return res.json()
    })
    .then(data=>{
        return {data, pages}
    });
}


function getTopProducts(params, globalFilter, fields){
    return config.live ? getTopProductsLive(params, globalFilter, fields) : getTopProductsStatic();
}

function getTopProductsLive(params, globalFilter, fields) {
    let baseUrl = config.apis.v3_data+'/simple',
        qs = BuildQS(globalFilter),
        requestOptions = {
            method: 'POST',
            headers: AuthHeader({'Content-Type': 'application/json'})
        };

    qs.query_name = 'top_products';
    // qs.offset = 0;

    let {sorted, filtered} = params;
    if(sorted !== undefined){
        qs.order_by = sorted.field;
        qs.order_dir = sorted.dir;
    }

    if(filtered !== undefined && filtered.length){
        for(let filter of filtered) {
            let value = filter.value;
            if(!value)
                continue;
            if(value instanceof Array) {
                value = value.join(',');
                if(!value)
                    continue;
                qs[filter.id] = value;
            }
            else {
                qs[filter.id] = value;
            }
        }
    }
    
    if(fields) {
        if(Array.isArray(fields))
            fields = fields.join(',');
        qs.fields = fields;
    }

    qs = BuildFilterQS(qs, 'products');
    //baseUrl += '?' + $.param(qs);
    requestOptions.body = JSON.stringify(qs);
    return fetch(baseUrl, requestOptions)
        .then(res=>res.json());
}



function getTopProductsStatic() {
    let baseUrl = `${config.apis.sales}/api/static/top_products_simple.json`;
    return fetch(baseUrl).then(res=>res.json());
}
/*
function getTopProductsStatic() {
    return Promise.resolve(topProducts);    
}
*/

function getProductsNew(params, globalFilter, basic = true) {
    let baseUrl = config.apis.v3_data+'/simple',
        qs = BuildQS(globalFilter),
        requestOptions = {
            method: 'POST',
            headers: AuthHeader({'Content-Type': 'application/json'})
        };

    qs.query_name = `cat_nav_products${basic?'_basic':''}`;
    qs.offset = params.page ? params.page*globalFilter.limit : 0;
    let {sorted, filtered} = params;
    if(sorted !== undefined && sorted.length){
        sorted = sorted[0];
        qs.order_by = sorted.id;
        qs.order_dir = sorted.desc ? 'desc':'asc';
    }

    if(filtered !== undefined && filtered.length){
        for(let filter of filtered) {
            let value = filter.value;
            if(!value)
                continue;
            if(value instanceof Array) {
                value = value.join(',');
                if(!value)
                    continue;
                qs[filter.id] = value;
            }
            else if(typeof value === 'string') { // TODO: kinda crappy way of determining full-string search
                qs.search_by = filter.id;
                qs.search_term = filter.value;
            }
            else {
                qs[filter.id] = value;
            }
        }
    }
    qs = BuildFilterQS(qs, 'products');
    //baseUrl += '?' + $.param(qs);
    requestOptions.body = JSON.stringify(qs);
    return fetch(baseUrl, requestOptions)
    .then(res=>{
        return res.json()
    })
}

function getProductsStatic(params, basic) {
    let baseUrl = `${config.apis.sales}/api/static/products${basic?'_basic':''}.json`;
    return fetch(baseUrl, {})
    .then(res=>{
        return res.json()
    });
}

function getProductsAutocomplete(input) {
    return searchService.autocomplete(input, ['products']);
}

function getCard(id) {
    let fields = 'id,name,logo_file_name',
        baseUrl = `${config.apis.miniCat}/v2/products/${id}?fields=${fields}`,
        requestOptions = {
            headers: AuthHeader()
        };
    return fetch(baseUrl, requestOptions)
    .then(res=>{
        return res.json()
    });
}

function getStreamsStats(ids, filtered, globalFilter) {
    return config.live ? getStreamsStatsLive(ids, filtered, globalFilter) : getStreamsStatsStatic();
}

function getStreamsStatsLive(ids, extFiltered, globalFilter) {

    //
    const params = {
        sorted:[{id: 'curr_units', 'desc': true}],
        filtered: [...extFiltered, {id:'ids', value:ids}]
    }
    //

    let baseUrl = config.apis.v3_data+'/simple',
    qs = BuildQS(globalFilter),
    requestOptions = {
        method: 'POST',
        headers: AuthHeader({'Content-Type': 'application/json'})
    };

    qs.query_name = 'top_products';
    // qs.offset = params.page ? params.page*globalFilter.limit : 0;
    qs.mode = 'timeseries_ids';

    let {sorted, filtered} = params;
    if(sorted !== undefined && sorted.length){
        sorted = sorted[0];
        qs.order_by = sorted.id;
        qs.order_dir = sorted.desc ? 'desc':'asc';
    }

    if(filtered !== undefined && filtered.length){
        for(let filter of filtered) {
            let value = filter.value;
            if(!value)
                continue;
            if(value instanceof Array) {
                value = value.join(',');
                if(!value)
                    continue;
                qs[filter.id] = value;
            }
            else {
                qs[filter.id] = value;
            }
        }
    }

    //baseUrl += '?' + Param(qs).replace(/%2C/g,',');
    requestOptions.body = JSON.stringify(qs);
    return fetch(baseUrl, requestOptions)
    .then(res=>{
        return res.json()
    });
}

function getStreamsStatsStatic() {
    let baseUrl = `${config.apis.sales}/api/static/top_products_streams.json`;
    return fetch(baseUrl, {})
    .then(res=>{
        return res.json()
    })
}

function getTracks(product_ids) {
    let baseUrl = `${config.apis.miniCat}/v2/products/acl_tracks`,
        requestOptions = {
            headers: AuthHeader({'Content-Type': 'application/json'}),
            method: 'POST',
            body: JSON.stringify({product_ids})
        };
        
    return fetch(baseUrl, requestOptions)
    .then(res=>{   
        return res.json()
    });

}

function getMetadata(ids, skipTracks = true){
    return true || config.live ? getMetadataLive(ids, skipTracks) : getMetadataStatic();
}

function getMetadataLive(ids, skipTracks) {
    ids = encodeURIComponent(ids.join(','));
    
    let baseUrl = `${config.apis.miniCat}/v2/products/by_barcode/?skip_tracks=${skipTracks}`,
        requestOptions = {
            method: 'POST',
            headers: AuthHeader({'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'}),
            body: 'barcodes='+ids
        };
    
    return fetch(baseUrl, requestOptions)
    .then(res=>{   
        return res.json()
    });
}

function getMetadataStatic() {
    let baseUrl = `${config.apis.sales}/api/static/products_metadata.json`;
    return fetch(baseUrl, {})
    .then(res=>{   
        return res.json()
    });
}

function getMetadataByIDs(ids){
    return true || config.live ? getMetadataByIDsLive(ids) : getMetadataByIDsStatic();
}

function getMetadataByIDsLive(ids, skipTracks) {
    ids = encodeURIComponent(ids.join(','));
    
    let baseUrl = `${config.apis.miniCat}/v2/products/by_id/${ids}`,
        requestOptions = {
            method: 'GET',
            headers: AuthHeader({'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'})
        };
    
    return fetch(baseUrl, requestOptions)
    .then(res=>{   
        return res.json()
    });
}

function getMetadataByIDsStatic() {
    let baseUrl = `${config.apis.sales}/api/static/products_by_id_metadata.json`;
    return fetch(baseUrl, {})
    .then(res=>{   
        return res.json()
    });
}


/*
function getMetadataStatic() {
    return Promise.resolve(productsMetadata);    
}
*/


function getTimeseriesProducts(params, globalFilter){
    return config.live ? getTimeseriesProductsLive(params, globalFilter) : getTimeseriesProductsStatic();
}

function getTimeseriesProductsLive(params, globalFilter) {
    /*
    const params = {
        sorted:[{id: 'curr_units', 'desc': true}],
        filtered: [{id:'ids', value:ids}]
    }
    */
    
    let baseUrl = config.apis.v3_data+'/simple',
        qs = BuildQS(globalFilter),
        requestOptions = {
            method: 'POST',
            headers: AuthHeader({'Content-Type': 'application/json'})
        };

    qs.query_name = 'top_products';
    // qs.offset = 0;
    qs.mode = 'timeseries_ids';

    let {sorted, filtered, dateGroup} = params;
    if(sorted !== undefined){
        qs.order_by = sorted.field;
        qs.order_dir = sorted.dir;
    }

    if(filtered !== undefined && filtered.length){
        for(let filter of filtered) {
            let value = filter.value;
            if(!value)
                continue;
            if(value instanceof Array) {
                value = value.join(',');
                if(!value)
                    continue;
                qs[filter.id] = value;
            }
            else {
                qs[filter.id] = value;
            }
        }
    }
    if(dateGroup) {
        qs.date_grouping = dateGroup;
    }


    //baseUrl += '?' + Param(qs);
    requestOptions.body = JSON.stringify(qs);
    return fetch(baseUrl, requestOptions)
        .then(res=>res.json());
}

function getTimeseriesProductsStatic() {
    let baseUrl = `${config.apis.sales}/api/static/top_products_timeseries.json`;
    return fetch(baseUrl).then(res=>res.json());
}

/*
function getTimeseriesProductsStatic() {
    return Promise.resolve(topProductsTimeline);
}
*/

function compareProducts(ids, weeks, releaseDates, territories, vendors, user){
    return config.live ? compareProductsLive(ids, weeks, releaseDates, territories, vendors, user) : compareProductsStatic();
}

function compareProductsLive(ids, weeks, releaseDates, territories, vendors, user) {
    let baseUrl = config.apis.v3_data+'/track-comparison',
        qs = {},
        requestOptions = {
            method: 'POST',
            headers: AuthHeader({'Content-Type': 'application/json'})
        };


    qs.ids = Array.isArray(ids) ? ids.join(',') : ids;
    qs.release_dates = Array.isArray(releaseDates) ? releaseDates.join(',') : releaseDates;
    qs.weeks = weeks;
    qs.territories = territories;
    qs.vendors = vendors;
    qs.user_id = user.id;
    qs.client_admin = user.client_admin;
    qs.silo_code = user.primary_silo_code;
    qs.entity = 'main';
    qs.object = 'product';
    //baseUrl += '?' + Param(qs);
    requestOptions.body = JSON.stringify(qs);
    return fetch(baseUrl, requestOptions)
        .then(res=>res.json());
}

function compareProductsStatic() { 
    let baseUrl = `${config.apis.sales}/api/static/product_comparison.json`;
    return fetch(baseUrl).then(res=>res.json());
}

/*
function compareProductsStatic() {
    return Promise.resolve(comparisonMainData);
}
*/

function compareProductDemographics(id, weeks, releaseDate, territories, vendors, user){
    return config.live ? compareProductDemographicsLive(id, weeks, releaseDate, territories, vendors, user) : compareProductDemographicsStatic();
}

function compareProductDemographicsLive(id, weeks, releaseDate, territories, vendors, user) {
    let baseUrl = config.apis.v3_data+'/track-comparison',
        qs = {},
        requestOptions = {
            method: 'POST',
            headers: AuthHeader({'Content-Type': 'application/json'})
        };


    qs.ids = id;
    qs.release_dates = releaseDate;
    qs.weeks = weeks;
    qs.territories = territories;
    qs.vendors = vendors;
    qs.user_id = user.id;
    qs.client_admin = user.client_admin;
    qs.silo_code = user.primary_silo_code;
    qs.entity = 'demographics';
    qs.object = 'product';

    //baseUrl += '?' + Param(qs);
    requestOptions.body = JSON.stringify(qs);
    return fetch(baseUrl, requestOptions)
        .then(res=>res.json());
}

function compareProductDemographicsStatic() { 
    let baseUrl = `${config.apis.sales}/api/static/product_comparison_demographics.json`;
    return fetch(baseUrl).then(res=>res.json());
}

/*
function compareProductDemographicsStatic() {
    return Promise.resolve(comparisonDemographicsData);
}
*/


function compareProductTerritories(id, weeks, releaseDate, territories, vendors, user){
    return config.live ? compareProductTerritoriesLive(id, weeks, releaseDate, territories, vendors, user) : compareProductTerritoriesStatic();
}

function compareProductTerritoriesLive(id, weeks, releaseDate, territories, vendors, user) {
    let baseUrl = config.apis.v3_data+'/track-comparison',
        qs = {},
        requestOptions = {
            method: 'POST',
            headers: AuthHeader({'Content-Type': 'application/json'})
        };


    qs.ids = id;
    qs.weeks = weeks;
    qs.territories = territories;
    qs.vendors = vendors;
    qs.release_dates = releaseDate;
    qs.user_id = user.id;
    qs.client_admin = user.client_admin;
    qs.silo_code = user.primary_silo_code;
    qs.entity = 'territories';
    qs.object = 'product';
    //baseUrl += '?' + Param(qs);
    requestOptions.body = JSON.stringify(qs);
    return fetch(baseUrl, requestOptions)
        .then(res=>res.json());
}

function compareProductTerritoriesStatic() { 
    let baseUrl = `${config.apis.sales}/api/static/product_comparison_territories.json`;
    return fetch(baseUrl).then(res=>res.json());
}

function compareProductDatetime(id, weeks, releaseDate, territories, vendors, user){
    return false && config.live ? compareProductDatetimeLive(id, weeks, releaseDate, territories, vendors, user) : compareProductDatetimeStatic();
}

function compareProductArtist(id, artistID, weeks, releaseDate, territories, vendors, user){
    return config.live ? compareProductArtistLive(id, artistID, weeks, releaseDate, territories, vendors, user) : compareProductArtistStatic();
}

function compareProductArtistLive(id, artistID, weeks, releaseDate, territories, vendors, user) {
  let baseUrl = config.apis.v3_data+'/track-comparison',
          qs = {},
          requestOptions = {
              method: 'POST',
              headers: AuthHeader({'Content-Type': 'application/json'})
          };

  
      qs.ids = id;
      qs.artist_id = artistID;
      qs.weeks = weeks;
      qs.territories = territories;
      qs.vendors = vendors;
      qs.release_dates = releaseDate;
      qs.user_id = user.id;
      qs.client_admin = user.client_admin;
      qs.silo_code = user.primary_silo_code;
      qs.entity = 'album_artist';
      qs.object = 'product';
      //baseUrl += '?' + Param(qs);
      requestOptions.body = JSON.stringify(qs);
      return fetch(baseUrl, requestOptions)
          .then(res=>res.json());
}

function compareProductDatetimeLive(id, weeks, releaseDate, territories, vendors, user) {
    let baseUrl = config.apis.v3_data+'/track-comparison',
        qs = {},
        requestOptions = {
            method: 'POST',
            headers: AuthHeader({'Content-Type': 'application/json'})
        };


    qs.ids = id;
    qs.weeks = weeks;
    qs.territories = territories;
    qs.vendors = vendors;
    qs.release_dates = releaseDate;
    qs.user_id = user.id;
    qs.client_admin = user.client_admin;
    qs.silo_code = user.primary_silo_code;
    qs.entity = 'datetime';
    qs.object = 'product';
    //baseUrl += '?' + Param(qs);
    requestOptions.body = JSON.stringify(qs);
    return fetch(baseUrl, requestOptions)
        .then(res=>res.json());
}

function compareProductArtistStatic() { 
    let baseUrl = `${config.apis.sales}/api/static/album_artist.json`;
    return fetch(baseUrl).then(res=>res.json());
}
function compareProductDatetimeStatic() { 
  return Promise.resolve(audienceDatetime);
}

function compareProductVendors(id, weeks, releaseDate, territories, vendors, user){
    return config.live ? compareProductVendorsLive(id, weeks, releaseDate, territories, vendors, user) : compareProductVendorsStatic();
}

function compareProductVendorsLive(id, weeks, releaseDate, territories, vendors, user) {
    let baseUrl = config.apis.v3_data+'/track-comparison',
        qs = {},
        requestOptions = {
            method: 'POST',
            headers: AuthHeader({'Content-Type': 'application/json'})
        };


    qs.ids = id;
    qs.weeks = weeks;
    qs.territories = territories;
    qs.vendors = vendors;
    qs.release_dates = releaseDate;
    qs.user_id = user.id;
    qs.client_admin = user.client_admin;
    qs.silo_code = user.primary_silo_code;
    qs.entity = 'vendors';
    qs.object = 'product';
    //baseUrl += '?' + Param(qs);
    requestOptions.body = JSON.stringify(qs);
    return fetch(baseUrl, requestOptions)
        .then(res=>res.json());
}

function compareProductVendorsStatic() { 
    let baseUrl = `${config.apis.sales}/api/static/product_comparison_vendors.json`;
    return fetch(baseUrl).then(res=>res.json());
}

function getOrderedTracks(ids){
    return true || config.live ? getgetOrderedTracksLive(ids) : getgetOrderedTracksStatic();
}

function getgetOrderedTracksLive(ids) {
  if(Array.isArray(ids))
    ids = encodeURIComponent(ids.join(','));
    
    let baseUrl = `${config.apis.miniCat}/v2/products/tracks_in_product/${ids}`,
        requestOptions = {
            method: 'GET',
            headers: AuthHeader({'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'}),
            //body: 'barcodes='+ids
        };
    
    return fetch(baseUrl, requestOptions)
    .then(res=>{   
        return res.json()
    });
}

function getOrderedTracksStatic() {
    let baseUrl = `${config.apis.sales}/api/static/tracks_in_product.json`;
    return fetch(baseUrl, {})
    .then(res=>{   
        return res.json()
    });
}
