import { playlistsService } from '../services/playlists';
import { errorActions } from './error';
import { audienceConstants } from '../constants/audience';
import { audienceService } from '../services/audience';
import { audienceFormatter } from '../formatters/audience';
import { find, map } from 'lodash';

export const audienceActions = {
    getStreamsStats,
    getDemographicsStats,
    getTerritoryStats,
    getDemographicsTerritoryStats,
    getSourceStats,
    getSourceDiscoveryStats,
    getSourceTimeseries,
    getSourceDiscoveryTimeseries,
    getSourcePlaylists,
    getSourceDiscoveryPlaylists,    
    getDeviceStats,
    getPlaylistStats,
    getTopPlaylistStats,
    getRepeatListeners,
    getRepeatListenersTimeseries,
    getRepeatListenersRatio
};

function getStreamsStats(id, entity, filtered, dateGroup) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const globalFilter = getState().filter.global;
        if(filtered === undefined)
            filtered = [{
            id: entity,
            value: id
        }];
        if(dateGroup) {
            filtered.push({
                id: 'date_grouping',
                value: dateGroup
            })
        }
        return audienceService.getStreamsStats({filtered}, globalFilter)
            .then(
                audience => {
                    const data = audienceFormatter.formatStreamsStats(audience);
                    dispatch(success(data, audience));
                    return data;
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: audienceConstants.GET_AUDIENCE_STREAMS_STATS_REQUEST } }
    function success(data, audience) { return { type: audienceConstants.GET_AUDIENCE_STREAMS_STATS_SUCCESS, data, audience } }
    function failure(error) { return { type: audienceConstants.GET_AUDIENCE_STREAMS_STATS_FAILURE, error } }
}

function getDemographicsStats(id, entity, filtered) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const globalFilter = getState().filter.global;
        
        if(filtered === undefined)
            filtered = [{
                id: entity,
                value: id
            }];
        const fields = 'age, gender, curr_units, passive, active';
        return audienceService.getDemographicsStats({filtered}, globalFilter, fields)
            .then(
                audience => {
                    const data = audienceFormatter.formatDemographicsStats(audience);
                    dispatch(success(data));
                    return data;
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: audienceConstants.GET_AUDIENCE_DEMOGRAPHICS_STATS_REQUEST } }
    function success(data) { return { type: audienceConstants.GET_AUDIENCE_DEMOGRAPHICS_STATS_SUCCESS, data} }
    function failure(error) { return { type: audienceConstants.GET_AUDIENCE_DEMOGRAPHICS_STATS_FAILURE, error } }
}

function getTerritoryStats(id, entity, filtered, limit) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const globalFilter = getState().filter.global;
        if(filtered === undefined)
            filtered = [{
                id: entity,
                value: id
            }];
        const fields = 'territory,listeners,total_streams,all_territories,all_territories_listeners';
        return audienceService.getTerritoryStats({filtered, limit}, globalFilter, fields)
            .then(
                audience => {
                    const data = audienceFormatter.formatTerritoryStats(audience);
                    dispatch(success(data));
                    return data;
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: audienceConstants.GET_AUDIENCE_TERRITORY_STATS_REQUEST } }
    function success(data) { return { type: audienceConstants.GET_AUDIENCE_TERRITORY_STATS_SUCCESS, data } }
    function failure(error) { return { type: audienceConstants.GET_AUDIENCE_TERRITORY_STATS_FAILURE, error } }
}

function getDemographicsTerritoryStats(filtered, territory) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const globalFilter = getState().filter.global;
        
        filtered.push({
            id: 'territories',
            value: territory
        });
        const fields = 'age, gender, listeners, total_streams, active, passive';
        return audienceService.getDemographicsTerritoryStats({filtered}, globalFilter, fields)
            .then(
                audience => {
                    const data = audienceFormatter.formatDemographicsTerritoryStats(audience);
                    dispatch(success(data, territory));
                    return data;
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: audienceConstants.GET_AUDIENCE_DEMOGRAPHICS_TERRITORY_STATS_REQUEST } }
    function success(data, territory) { return { type: audienceConstants.GET_AUDIENCE_DEMOGRAPHICS_TERRITORY_STATS_SUCCESS, data, territory } }
    function failure(error) { return { type: audienceConstants.GET_AUDIENCE_DEMOGRAPHICS_TERRITORY_STATS_FAILURE, error } }
}


function getSourceStats(id, entity, filtered) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const globalFilter = getState().filter.global;
        if(filtered === undefined)
            filtered = [{
                id: entity,
                value: id
            }];
        
        const fields = 'source, listeners, total_streams';
        return audienceService.getSourceStats({filtered}, globalFilter, false, fields)
            .then(
                audience => {
                    const data = audienceFormatter.formatSourceStats(audience);
                    dispatch(success(data));
                    return data;
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: audienceConstants.GET_AUDIENCE_SOURCE_STATS_REQUEST } }
    function success(data) { return { type: audienceConstants.GET_AUDIENCE_SOURCE_STATS_SUCCESS, data } }
    function failure(error) { return { type: audienceConstants.GET_AUDIENCE_SOURCE_STATS_FAILURE, error } }
}

function getSourceDiscoveryStats(id, entity, filtered) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const globalFilter = getState().filter.global;
        if(filtered === undefined)
            filtered = [{
                id: entity,
                value: id
            }];
        const fields = 'source, listeners, total_streams';
        return audienceService.getSourceStats({filtered}, globalFilter, true, fields)
            .then(
                audience => {
                    const data = audienceFormatter.formatSourceStats(audience);
                    dispatch(success(data));
                    return data;
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: audienceConstants.GET_AUDIENCE_SOURCE_DISCOVERY_STATS_REQUEST } }
    function success(data) { return { type: audienceConstants.GET_AUDIENCE_SOURCE_DISCOVERY_STATS_SUCCESS, data } }
    function failure(error) { return { type: audienceConstants.GET_AUDIENCE_SOURCE_DISCOVERY_STATS_FAILURE, error } }
}

function getSourceTimeseries(id, entity, filtered) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const globalFilter = getState().filter.global;
        if(filtered === undefined)
            filtered = [{
                id: entity,
                value: id
            }];

        return audienceService.getSourceTimeseries({filtered}, globalFilter, false)
            .then(
                audience => {
                    const data = audienceFormatter.formatSourceTimeseries(audience);
                    dispatch(success(data));
                    return data;
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: audienceConstants.GET_AUDIENCE_SOURCE_TIMESERIES_REQUEST } }
    function success(data) { return { type: audienceConstants.GET_AUDIENCE_SOURCE_TIMESERIES_SUCCESS, data } }
    function failure(error) { return { type: audienceConstants.GET_AUDIENCE_SOURCE_TIMESERIES_FAILURE, error } }
}

function getSourceDiscoveryTimeseries(id, entity, filtered) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const globalFilter = getState().filter.global;
        if(filtered === undefined)
            filtered = [{
                id: entity,
                value: id
            }];

        return audienceService.getSourceTimeseries({filtered}, globalFilter, true)
            .then(
                audience => {
                    const data = audienceFormatter.formatSourceTimeseries(audience);
                    dispatch(success(data));
                    return data;
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: audienceConstants.GET_AUDIENCE_SOURCE_DISCOVERY_TIMESERIES_REQUEST } }
    function success(data) { return { type: audienceConstants.GET_AUDIENCE_SOURCE_DISCOVERY_TIMESERIES_SUCCESS, data } }
    function failure(error) { return { type: audienceConstants.GET_AUDIENCE_SOURCE_DISCOVERY_TIMESERIES_FAILURE, error } }
}

function getSourcePlaylists(id, entity, filtered) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const globalFilter = getState().filter.global;
        if(filtered === undefined)
            filtered = [{
                id: entity,
                value: id
            }];

        return audienceService.getSourcePlaylists({filtered}, globalFilter, false)
            .then(
                audience => {
                    const data = audienceFormatter.formatSourcePlaylists(audience);
                    dispatch(success(data));
                    return data;
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: audienceConstants.GET_AUDIENCE_SOURCE_PLAYLISTS_REQUEST } }
    function success(data) { return { type: audienceConstants.GET_AUDIENCE_SOURCE_PLAYLISTS_SUCCESS, data } }
    function failure(error) { return { type: audienceConstants.GET_AUDIENCE_SOURCE_PLAYLISTS_FAILURE, error } }
}

function getSourceDiscoveryPlaylists(id, entity, filtered) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const globalFilter = getState().filter.global;
        if(filtered === undefined)
            filtered = [{
                id: entity,
                value: id
            }];

        return audienceService.getSourcePlaylists({filtered}, globalFilter, true)
            .then(
                audience => {
                    const data = audienceFormatter.formatSourcePlaylists(audience);
                    dispatch(success(data));
                    return data;
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: audienceConstants.GET_AUDIENCE_SOURCE_DISCOVERY_PLAYLISTS_REQUEST } }
    function success(data) { return { type: audienceConstants.GET_AUDIENCE_SOURCE_DISCOVERY_PLAYLISTS_SUCCESS, data } }
    function failure(error) { return { type: audienceConstants.GET_AUDIENCE_SOURCE_DISCOVERY_PLAYLISTS_FAILURE, error } }
}


function getPlaylistStats(id, entity) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const globalFilter = getState().filter.global;
        return audienceService.getPlaylistStats({filtered: [{
            id: entity,
            value: id
        }]}, globalFilter)
            .then(
                audience => {
                    const data = audienceFormatter.formatPlaylistStats(audience);
                    dispatch(success(data));
                    return data;
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: audienceConstants.GET_AUDIENCE_PLAYLIST_STATS_REQUEST } }
    function success(data) { return { type: audienceConstants.GET_AUDIENCE_PLAYLIST_STATS_SUCCESS, data } }
    function failure(error) { return { type: audienceConstants.GET_AUDIENCE_PLAYLIST_STATS_FAILURE, error } }
}

function getTopPlaylistStats(id, entity) {
    return ( dispatch, getState ) => {
        dispatch(request());
        
        const playlistsFilter = {filtered: [{id: entity, value: id}]};
        
        // ONLY QUERY SPOTIFY TOP PLAYLISTS!!
        let globalFilter = Object.assign({}, getState().filter.global);
            globalFilter.vendors = ['Spotify'];
            
        return playlistsService.getTopPlaylists(playlistsFilter, globalFilter)
            .then(playlists => {
                if(!playlists.length)
                    return dispatch(success([]));
                
                const playlistIDs = map(playlists, (playlist)=>playlist.playlist_id);
                
                return Promise.all([audienceService.getTopPlaylistStats({filtered: [{
                    id: 'playlists',
                    value: playlistIDs.join(',')
                }]}, globalFilter),
                playlistsService.getMetadata(playlistIDs)]);
            })
            .then(data => {
                const audience = data[0],
                    metadata = data[1];
                
                data = audienceFormatter.formatTopPlaylistStatsMeta(audience, metadata);
                dispatch(success(data));
                return data;
            })
    };

    function request() { return { type: audienceConstants.GET_AUDIENCE_TOP_PLAYLIST_STATS_REQUEST } }
    function success(data) { return { type: audienceConstants.GET_AUDIENCE_TOP_PLAYLIST_STATS_SUCCESS, data } }
    function failure(error) { return { type: audienceConstants.GET_AUDIENCE_TOP_PLAYLIST_STATS_FAILURE, error } }
}

function getRepeatListeners(id, entity, filtered) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const globalFilter = getState().filter.global;
        if(filtered === undefined)
            filtered = [{
                id: entity,
                value: id
            }];
        return audienceService.getRepeatListeners({filtered}, globalFilter)
            .then(
                audience => {
                    const data = audienceFormatter.formatRepeatListeners(audience);
                    dispatch(success(data));
                    return data;
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: audienceConstants.GET_AUDIENCE_REPEAT_LISTENERS_REQUEST } }
    function success(data) { return { type: audienceConstants.GET_AUDIENCE_REPEAT_LISTENERS_SUCCESS, data } }
    function failure(error) { return { type: audienceConstants.GET_AUDIENCE_REPEAT_LISTENERS_FAILURE, error } }
}

function getRepeatListenersTimeseries(id, entity, filtered) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const globalFilter = getState().filter.global;
        if(filtered === undefined)
            filtered = [{
                id: entity,
                value: id
            }];
        return audienceService.getRepeatListenersTimeseries({filtered}, globalFilter)
            .then(
                audience => {
                    const data = audienceFormatter.formatRepeatListenersTimeseries(audience);
                    dispatch(success(data));
                    return data;
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: audienceConstants.GET_AUDIENCE_REPEAT_LISTENERS_TIMESERIES_REQUEST } }
    function success(data) { return { type: audienceConstants.GET_AUDIENCE_REPEAT_LISTENERS_TIMESERIES_SUCCESS, data } }
    function failure(error) { return { type: audienceConstants.GET_AUDIENCE_REPEAT_LISTENERS_TIMESERIES_FAILURE, error } }
}

function getRepeatListenersRatio(id, entity, filtered, dateGroup) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const globalFilter = getState().filter.global;
        if(filtered === undefined)
            filtered = [{
                id: entity,
                value: id
            }];
        if(dateGroup) {
            filtered.push({
                id: 'date_grouping',
                value: dateGroup
            })
        }            
        return audienceService.getRepeatListenersRatio({filtered}, globalFilter)
            .then(
                audience => {
                    const data = audienceFormatter.formatRepeatListenersRatio(audience);
                    dispatch(success(data));
                    return data;
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: audienceConstants.GET_AUDIENCE_REPEAT_LISTENERS_RATIO_REQUEST } }
    function success(data) { return { type: audienceConstants.GET_AUDIENCE_REPEAT_LISTENERS_RATIO_SUCCESS, data } }
    function failure(error) { return { type: audienceConstants.GET_AUDIENCE_REPEAT_LISTENERS_RATIO_FAILURE, error } }
}

function getDeviceStats(id, entity, filtered) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const globalFilter = getState().filter.global;
        if(filtered === undefined)
            filtered = [{
                id: entity,
                value: id
            }];
        const fields = 'device, listeners, total_streams';
        return audienceService.getDeviceStats({filtered}, globalFilter, fields)
            .then(
                audience => {
                    const data = audienceFormatter.formatDeviceStats(audience);
                    dispatch(success(data));
                    return data;
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: audienceConstants.GET_AUDIENCE_DEVICE_STATS_REQUEST } }
    function success(data) { return { type: audienceConstants.GET_AUDIENCE_DEVICE_STATS_SUCCESS, data } }
    function failure(error) { return { type: audienceConstants.GET_AUDIENCE_DEVICE_STATS_FAILURE, error } }
}
