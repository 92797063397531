export const audienceConstants = {
    GET_AUDIENCE_STREAMS_STATS_REQUEST: 'GET_AUDIENCE_STREAMS_STATS_REQUEST',
    GET_AUDIENCE_STREAMS_STATS_SUCCESS: 'GET_AUDIENCE_STREAMS_STATS_SUCCESS',
    GET_AUDIENCE_STREAMS_STATS_FAILURE: 'GET_AUDIENCE_STREAMS_STATS_FAILURE',
    GET_AUDIENCE_DEMOGRAPHICS_STATS_REQUEST: 'GET_AUDIENCE_DEMOGRAPHICS_STATS_REQUEST',
    GET_AUDIENCE_DEMOGRAPHICS_STATS_SUCCESS: 'GET_AUDIENCE_DEMOGRAPHICS_STATS_SUCCESS',
    GET_AUDIENCE_DEMOGRAPHICS_STATS_FAILURE: 'GET_AUDIENCE_DEMOGRAPHICS_STATS_FAILURE',
    GET_AUDIENCE_TERRITORY_STATS_REQUEST: 'GET_AUDIENCE_TERRITORY_STATS_REQUEST',
    GET_AUDIENCE_TERRITORY_STATS_SUCCESS: 'GET_AUDIENCE_TERRITORY_STATS_SUCCESS',
    GET_AUDIENCE_TERRITORY_STATS_FAILURE: 'GET_AUDIENCE_TERRITORY_STATS_FAILURE',
    GET_AUDIENCE_DEMOGRAPHICS_TERRITORY_STATS_REQUEST: 'GET_AUDIENCE_DEMOGRAPHICS_TERRITORY_STATS_REQUEST',
    GET_AUDIENCE_DEMOGRAPHICS_TERRITORY_STATS_SUCCESS: 'GET_AUDIENCE_DEMOGRAPHICS_TERRITORY_STATS_SUCCESS',
    GET_AUDIENCE_DEMOGRAPHICS_TERRITORY_STATS_FAILURE: 'GET_AUDIENCE_DEMOGRAPHICS_TERRITORY_STATS_FAILURE',    
    GET_AUDIENCE_SOURCE_STATS_REQUEST: 'GET_AUDIENCE_SOURCE_STATS_REQUEST',
    GET_AUDIENCE_SOURCE_STATS_SUCCESS: 'GET_AUDIENCE_SOURCE_STATS_SUCCESS',
    GET_AUDIENCE_SOURCE_STATS_FAILURE: 'GET_AUDIENCE_SOURCE_STATS_FAILURE',
    GET_AUDIENCE_SOURCE_DISCOVERY_STATS_REQUEST: 'GET_AUDIENCE_SOURCE_DISCOVERY_STATS_REQUEST',
    GET_AUDIENCE_SOURCE_DISCOVERY_STATS_SUCCESS: 'GET_AUDIENCE_SOURCE_DISCOVERY_STATS_SUCCESS',
    GET_AUDIENCE_SOURCE_DISCOVERY_STATS_FAILURE: 'GET_AUDIENCE_SOURCE_DISCOVERY_STATS_FAILURE',    
    GET_AUDIENCE_PLAYLIST_STATS_REQUEST: 'GET_AUDIENCE_PLAYLIST_STATS_REQUEST',
    GET_AUDIENCE_PLAYLIST_STATS_SUCCESS: 'GET_AUDIENCE_PLAYLIST_STATS_SUCCESS',
    GET_AUDIENCE_PLAYLIST_STATS_FAILURE: 'GET_AUDIENCE_PLAYLIST_STATS_FAILURE',
    GET_AUDIENCE_TOP_PLAYLIST_STATS_REQUEST: 'GET_AUDIENCE_TOP_PLAYLIST_STATS_REQUEST',
    GET_AUDIENCE_TOP_PLAYLIST_STATS_SUCCESS: 'GET_AUDIENCE_TOP_PLAYLIST_STATS_SUCCESS',
    GET_AUDIENCE_TOP_PLAYLIST_STATS_FAILURE: 'GET_AUDIENCE_TOP_PLAYLIST_STATS_FAILURE',    
    GET_AUDIENCE_REPEAT_LISTENERS_REQUEST: 'GET_AUDIENCE_REPEAT_LISTENERS_REQUEST',
    GET_AUDIENCE_REPEAT_LISTENERS_SUCCESS: 'GET_AUDIENCE_REPEAT_LISTENERS_SUCCESS',
    GET_AUDIENCE_REPEAT_LISTENERS_FAILURE: 'GET_AUDIENCE_REPEAT_LISTENERS_FAILURE',
    GET_AUDIENCE_REPEAT_LISTENERS_TIMESERIES_REQUEST: 'GET_AUDIENCE_REPEAT_LISTENERS_TIMESERIES_REQUEST',
    GET_AUDIENCE_REPEAT_LISTENERS_TIMESERIES_SUCCESS: 'GET_AUDIENCE_REPEAT_LISTENERS_TIMESERIES_SUCCESS',
    GET_AUDIENCE_REPEAT_LISTENERS_TIMESERIES_FAILURE: 'GET_AUDIENCE_REPEAT_LISTENERS_TIMESERIES_FAILURE',
    GET_AUDIENCE_REPEAT_LISTENERS_RATIO_REQUEST: 'GET_AUDIENCE_REPEAT_LISTENERS_RATIO_REQUEST',
    GET_AUDIENCE_REPEAT_LISTENERS_RATIO_SUCCESS: 'GET_AUDIENCE_REPEAT_LISTENERS_RATIO_SUCCESS',
    GET_AUDIENCE_REPEAT_LISTENERS_RATIO_FAILURE: 'GET_AUDIENCE_REPEAT_LISTENERS_RATIO_FAILURE',
    GET_AUDIENCE_DEVICE_STATS_REQUEST: 'GET_AUDIENCE_DEVICE_STATS_REQUEST',
    GET_AUDIENCE_DEVICE_STATS_SUCCESS: 'GET_AUDIENCE_DEVICE_STATS_SUCCESS',
    GET_AUDIENCE_DEVICE_STATS_FAILURE: 'GET_AUDIENCE_DEVICE_STATS_FAILURE',       
    GET_AUDIENCE_SOURCE_TIMESERIES_REQUEST: 'GET_AUDIENCE_SOURCE_TIMESERIES_REQUEST',
    GET_AUDIENCE_SOURCE_TIMESERIES_SUCCESS: 'GET_AUDIENCE_SOURCE_TIMESERIES_SUCCESS',
    GET_AUDIENCE_SOURCE_TIMESERIES_FAILURE: 'GET_AUDIENCE_SOURCE_TIMESERIES_FAILURE',
    GET_AUDIENCE_SOURCE_DISCOVERY_TIMESERIES_REQUEST: 'GET_AUDIENCE_SOURCE_DISCOVERY_TIMESERIES_REQUEST',
    GET_AUDIENCE_SOURCE_DISCOVERY_TIMESERIES_SUCCESS: 'GET_AUDIENCE_SOURCE_DISCOVERY_TIMESERIES_SUCCESS',
    GET_AUDIENCE_SOURCE_DISCOVERY_TIMESERIES_FAILURE: 'GET_AUDIENCE_SOURCE_DISCOVERY_TIMESERIES_FAILURE',    
    GET_AUDIENCE_SOURCE_PLAYLISTS_REQUEST: 'GET_AUDIENCE_SOURCE_PLAYLISTS_REQUEST',
    GET_AUDIENCE_SOURCE_PLAYLISTS_SUCCESS: 'GET_AUDIENCE_SOURCE_PLAYLISTS_SUCCESS',
    GET_AUDIENCE_SOURCE_PLAYLISTS_FAILURE: 'GET_AUDIENCE_SOURCE_PLAYLISTS_FAILURE',
    GET_AUDIENCE_SOURCE_DISCOVERY_PLAYLISTS_REQUEST: 'GET_AUDIENCE_SOURCE_DISCOVERY_PLAYLISTS_REQUEST',
    GET_AUDIENCE_SOURCE_DISCOVERY_PLAYLISTS_SUCCESS: 'GET_AUDIENCE_SOURCE_DISCOVERY_PLAYLISTS_SUCCESS',
    GET_AUDIENCE_SOURCE_DISCOVERY_PLAYLISTS_FAILURE: 'GET_AUDIENCE_SOURCE_DISCOVERY_PLAYLISTS_FAILURE',    
    
}